// filename: src/App.vue
<template>
  <div
      id="app"
      :class="{'app-layout': true, 'profile-layout': isProfileRoute, 'default-layout': !isProfileRoute}"
      @mousemove="resetTimer"
      @click="resetTimer"
      @keypress="resetTimer"
  >
    <Header />

    <main class="main">
      <router-view/>
    </main>

    <Footer v-if="!isProfileRoute" />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";

import { useAuthStore } from "@/stores/auth";

export default {
  name: 'App',
  components: { Header, Footer },
  setup() {
    const route = useRoute();
    const isProfileRoute = computed(() => route.path.includes('profil'));

    onMounted(() => {
      if (route.path.includes('/profil/')) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    });

    return {
      isProfileRoute,
    };
  },
  data() {
    return {
      inactivityTimeout: null,
    };
  },
  mounted() {
    this.startInactivityTimer();
  },
  beforeUnmount() {
    this.clearInactivityTimer();
  },
  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer(); // Clear any existing timer
      this.inactivityTimeout = setTimeout(this.logoutUser, 60 * 60 * 1000); // 1 hour

    },
    resetTimer() {
      this.startInactivityTimer();
    },
    clearInactivityTimer() {
      if (this.inactivityTimeout) {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = null;
      }
    },
    logoutUser() {
      const authStore = useAuthStore();
      authStore.logout(); // Call the logout action from the auth store
      this.$router.push({ name: 'login' }); // Redirect to login page
    },
  },
};
</script>

<style lang="scss">
/* Global styles */
@use '@/styles/global-styles-index';

.app-layout {
  display: grid;
  min-height: 100vh;
}

.default-layout {
  grid-template-rows: 64px 1fr auto;

  @media (min-width: 768px) {
    grid-template-rows: 64px auto 60px;
  }
}

.profile-layout {
  grid-template-rows: 64px auto;

  @media (min-width: 768px) {
    grid-template-rows: 64px auto;
  }
}
</style>
